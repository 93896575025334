import { geoClipAntimeridian, geoClipCircle, geoProjectionMutator } from 'd3-geo'
import { Expo } from 'gsap'
import { projectionIds } from '@/assets/js/model/projection'

export function interpolateProjection(fromProjection, toProjection, t) {
  const morphAdapter = getProjectionMorphClippingAdapter(fromProjection, toProjection, t)

  const mutate = geoProjectionMutator((t) => (x, y) => {
    const [x0, y0] = fromProjection.raw(x, y)
    const [x1, y1] = toProjection.raw(x, y)
    return [x0 + t * (x1 - x0), y0 + t * (y1 - y0)]
  })

  return mutate(t).preclip(morphAdapter)
}

export function getProjectionMorphClippingAdapter(fromProjection, toProjection, t) {
  if (fromProjection.id === projectionIds.ORTHOGRAPHIC) {
    return (stream) => {
      const val = Math.PI / 2 + (t * Math.PI) / 2
      return geoClipCircle(val)(geoClipAntimeridian(stream))
    }
  }

  if (toProjection.id === projectionIds.ORTHOGRAPHIC) {
    return (stream) => {
      const val = Math.PI / 2 + ((1 - t) * Math.PI) / 2
      return geoClipCircle(val)(geoClipAntimeridian(stream))
    }
  }

  return (stream) => geoClipAntimeridian(stream)
}

export const projectionMorphConfig = {
  fromVars: { t: 0 },
  toVars: {
    t: 1,
    paused: true,
    immediateRender: false,
    ease: Expo.easeInOut,
    onUpdate() {
      const { t } = this.projectionMorph.tween.targets()[0]

      this.projectionMorph.projection = interpolateProjection(
        this.projectionMorph.from,
        this.projectionMorph.to,
        t,
      )

      this.$set(this, 'loadedProjection', {
        id: `${this.projectionId}@${t}`,
        value: this.projectionMorph.projection,
      })
    },
    onComplete() {
      this.$set(this, 'loadedProjection', {
        id: this.projectionId,
        value: this.projectionMorph.to.data,
      })
    },
  },
}
