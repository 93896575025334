<template>
  <div :class="rootClasses">
    <client-only>
      <world-map-viewer
        v-if="isPrepared"
        :id="id"
        :allow-rotation="allowRotation"
        :allow-zoom="allowZoom"
        :center="center"
        :has-watermark="hasWatermark"
        :height="height"
        :layers="loadedLayers"
        :map-padding="mapPadding"
        :map-theme-id="mapThemeId"
        :projection="loadedProjection"
        :rendering-adapter="loadedRenderingAdapter"
        :rotation="rotation"
        :width="width"
        :zoom="zoom"
        class="world-map__el"
        @map-drawn="$emit('map-drawn', $event)"
        @map-interaction="onMapInteraction($event)"
      />
    </client-only>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { gsap } from 'gsap'
  import bemMixin from '@/assets/js/mixin/bem'
  import WorldMapViewer from '@/components/world-map-viewer/world-map-viewer'
  import { renderingAdapterIds, renderingAdapterList } from '@/assets/js/model/export'
  import { projectionIds } from '@/assets/js/model/projection'
  import { layerList } from '@/assets/js/model/layer'
  import { projectionMorphConfig } from '@/components/world-map/util'

  export default {
    components: { WorldMapViewer },
    mixins: [bemMixin('world-map')],
    inheritAttrs: false,
    props: {
      projectionMorphDuration: {
        type: Number,
        default: 0,
      },
      allowRotation: {
        type: Boolean,
        default: false,
      },
      allowZoom: {
        type: Boolean,
        default: false,
      },
      hasWatermark: {
        type: Boolean,
        default: false,
      },
      facets: {
        type: Array,
        default() {
          return []
        },
      },
      id: {
        type: String,
        required: true,
      },
      layerIds: {
        type: Array,
        required: true,
      },
      projectionId: {
        type: String,
        required: true,
        validator(value) {
          return Object.values(projectionIds).includes(value)
        },
      },
      renderingAdapterId: {
        type: String,
        required: true,
        validator(value) {
          return Object.values(renderingAdapterIds).includes(value)
        },
      },
      center: {
        type: Array,
        required: true,
      },
      rotation: {
        type: Array,
        required: true,
      },
      zoom: {
        type: Number,
        default: null,
      },
      mapThemeId: {
        type: String,
        required: true,
      },
      targetWidth: {
        type: Number,
        default: 0,
      },
      targetHeight: {
        type: Number,
        default: 0,
      },
      mapPadding: {
        type: Array,
        default() {
          return [0, 0, 0, 0]
        },
      },
    },
    data() {
      return {
        projectionMorph: {
          tween: null,
          projection: null,
          t: 0,
          tDirection: null,
          from: null,
          to: null,
        },
        isMounted: false,
        isDragging: false,
        isZooming: false,
        height: 0,
        width: 0,
        loadedLayers: [],
        loadedProjection: {},
        loadedRenderingAdapter: {},
      }
    },
    computed: {
      ...mapState('viewport', {
        viewportWidth: 'width',
        viewportHeight: 'height',
      }),
      ...mapState('world-map', [
        'mapConfigs',
        'layerData',
        'projectionData',
        'renderingAdapterData',
      ]),
      rootClasses() {
        return [
          ...this.bemFacets,
          this.bemAdd(this.allowRotation ? 'is-rotatable' : ''),
          this.bemAdd(this.allowZoom ? 'is-zoomable' : ''),
          this.bemAdd(this.isDragging ? 'is-dragging' : ''),
        ]
      },
      hasDimensions() {
        return [this.width, this.height, this.viewportWidth, this.viewportHeight].every(Boolean)
      },
      isPrepared() {
        return [
          this.isMounted,
          this.hasDimensions,
          this.loadedLayers.length > 0,
          this.loadedProjection.id,
          this.loadedRenderingAdapter.id,
        ].every(Boolean)
      },
      viewportDependencies() {
        return [
          this.isMounted,
          this.targetWidth,
          this.targetHeight,
          this.viewportWidth,
          this.viewportHeight,
        ].join('|')
      },
    },
    watch: {
      layerIds(newLayerIds) {
        this.onLayerIdsChanged(newLayerIds)
      },
      projectionId(newProjectionId, oldProjectionId) {
        this.onProjectionChanged(newProjectionId, oldProjectionId)
      },
      renderingAdapterId(newRenderingAdapterId) {
        this.onRenderingAdapterChanged(newRenderingAdapterId)
      },
      viewportDependencies() {
        const { width, height } = this.$el.getBoundingClientRect()
        this.width = this.targetWidth || width
        this.height = this.targetHeight || height
      },
    },
    mounted() {
      this.isMounted = true
      this.onLayerIdsChanged(this.layerIds)
      this.onProjectionChanged(this.projectionId)
      this.onRenderingAdapterChanged(this.renderingAdapterId)

      this.projectionMorph.tween = gsap.fromTo(
        this.projectionMorph,
        projectionMorphConfig.fromVars,
        { ...projectionMorphConfig.toVars, callbackScope: this },
      )
    },
    methods: {
      ...mapActions('world-map', ['loadLayer', 'loadProjection', 'loadRenderingAdapter']),
      async onLayerIdsChanged(layerIds) {
        await Promise.all(layerIds.map((id) => this.loadLayer({ id })))

        const loadedLayers = layerIds
          .map((loadedLayerId) => ({
            id: loadedLayerId,
            config: layerList.find(({ id }) => id === loadedLayerId),
            data: this.layerData[loadedLayerId],
          }))
          .sort(({ config: a }, { config: b }) => a.layerIndex - b.layerIndex)

        this.$set(this, 'loadedLayers', loadedLayers)
      },
      async onProjectionChanged(newProjectionId, oldProjectionId) {
        await this.loadProjection({ id: newProjectionId })

        const oldProjection = this.projectionData[oldProjectionId] || {}
        const newProjection = this.projectionData[newProjectionId] || {}

        if (
          this.projectionMorphDuration === 0 ||
          !oldProjection.isMorphable ||
          !newProjection.isMorphable
        ) {
          this.$set(this, 'loadedProjection', { id: newProjectionId, value: newProjection.data })
        } else {
          this.morphProjection(oldProjection, newProjection)
        }
      },
      async onRenderingAdapterChanged(value) {
        await this.loadRenderingAdapter({ id: value })

        const loadedRenderingAdapter = {
          id: value,
          adapter: this.renderingAdapterData[value],
          config: renderingAdapterList.find(({ id }) => id === value),
        }

        this.$set(this, 'loadedRenderingAdapter', loadedRenderingAdapter)
      },
      onMapInteraction({ isDragging, isZooming }) {
        if (isDragging != null) {
          this.isDragging = isDragging
        }

        if (isZooming != null) {
          this.isZooming = isZooming
        }
      },
      morphProjection(oldValue, newValue) {
        this.projectionMorph.from = oldValue
        this.projectionMorph.to = newValue
        this.projectionMorph.tween.duration(this.projectionMorphDuration / 1000).play(0)
      },
    },
  }
</script>

<style lang="scss" src="./_world-map.scss" />
