import { select } from 'd3-selection'
import { zoom, zoomIdentity } from 'd3-zoom'

export const DRAG_TIMEOUT = 1200
export const THROTTLE_INTERVAL = 40

export const MAP_DATA_SPHERE = { type: 'Sphere' }
export const MAP_SCALE_EXTENT = [0.5, 20]

/**
 * Set the zoom properties on the world map element
 * @param {any} element - The world map element
 * @param {number} scale - The new scale
 */
export function setScaleProperty(element, scale) {
  select(element).property('__zoom', zoomIdentity.scale(scale))
}

/**
 * Return an extent object
 * @param {number[]} padding - The map padding
 * @param {number} width - The target width
 * @param {number} height - The target height
 * @return {any} The extent array with top left coordinates and bottom right coordinates
 */
export function getMapExtent([top, right, bottom, left], width, height) {
  return [
    [left, top],
    [width - right, height - bottom],
  ]
}

/**
 * Zoom and drag manipulation initiator
 * @param {number} baseScale - The zoom base scale
 * @param {number[]} baseScaleExtent - The allowable extent
 * @param {number} zoomFactor - The zoom factor
 * @return {any} An improved d3 zoom object
 */
export function implementZoom({ baseScale, baseScaleExtent, zoomFactor }) {
  const scale = zoomFactor * baseScale
  const scaleExtent = baseScaleExtent.map((n) => n / zoomFactor)

  return zoom().scaleExtent(scaleExtent.map((x) => x * scale))
}
