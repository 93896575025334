<template>
  <component
    :is="renderingAdapter.config.tag"
    :height="height"
    :width="width"
    class="world-map-viewer"
  />
</template>

<script>
  import { throttle } from 'lodash-es'
  import { mapActions, mapState } from 'vuex'
  import { geoInertiaDrag } from 'd3-inertia'
  import { select } from 'd3-selection'
  import {
    DRAG_TIMEOUT,
    getMapExtent,
    implementZoom,
    MAP_DATA_SPHERE,
    MAP_SCALE_EXTENT,
    setScaleProperty,
    THROTTLE_INTERVAL,
  } from '@/components/world-map-viewer/util'
  import bemMixin from '@/assets/js/mixin/bem'
  import { layerTypeIds } from '@/assets/js/model/layer'
  import { mapThemeList } from '@/assets/js/model/map-theme'

  let DRAG_END_TIMEOUT = null

  export default {
    mixins: [bemMixin('world-map-viewer')],
    props: {
      allowRotation: {
        type: Boolean,
        default: false,
      },
      allowZoom: {
        type: Boolean,
        default: false,
      },
      hasWatermark: {
        type: Boolean,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      layers: {
        type: Array,
        required: true,
      },
      projection: {
        type: Object,
        required: true,
      },
      renderingAdapter: {
        type: Object,
        required: true,
      },
      center: {
        type: Array,
        required: true,
      },
      rotation: {
        type: Array,
        required: true,
      },
      zoom: {
        type: Number,
        default: null,
      },
      mapThemeId: {
        type: String,
        required: true,
      },
      width: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 0,
      },
      mapPadding: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        isMounted: false,
        baseScale: null,
      }
    },
    computed: {
      ...mapState('world-map', ['layerData', 'projectionData']),
      theme() {
        return mapThemeList.find(({ id }) => id === this.mapThemeId)
      },
      drawDependencies() {
        return [
          this.isMounted,
          this.zoom,
          this.projection.id,
          this.renderingAdapter.id,
          this.mapThemeId,
          this.width,
          this.height,
          ...Object.values(this.rotation),
          ...this.layers.map(({ id }) => id),
        ].join('|')
      },
      manipulationDependencies() {
        return [
          this.isMounted,
          this.allowRotation,
          this.allowZoom,
          this.projection.id,
          this.width,
          this.height,
          ...this.mapPadding,
        ].join('|')
      },
    },
    watch: {
      drawDependencies: {
        handler() {
          if (this.isMounted) {
            // NOTE: A tick is necessary as the element must have time to possibly change between svg and canvas
            this.$nextTick(this.throttledDraw)
          }
        },
        immediate: true,
      },
      manipulationDependencies: {
        handler() {
          if (this.isMounted) {
            this.handleScale()

            if (this.allowRotation) {
              this.implementRotation()
            }

            if (this.allowZoom) {
              this.implementZoom()
            }

            this.$nextTick(this.throttledDraw)
          }
        },
        immediate: true,
      },
    },
    created() {
      this.throttledDraw = throttle(this.draw, THROTTLE_INTERVAL)
      this.throttledOnDragging = throttle(this.onDragging, THROTTLE_INTERVAL)
      this.throttledOnZooming = throttle(this.onZooming, THROTTLE_INTERVAL)
    },
    mounted() {
      this.isMounted = true
    },
    methods: {
      ...mapActions('world-map', ['updateMapConfig']),
      getStations() {
        const { PERSONA_STATIONS } = layerTypeIds
        const stationsLayer = this.layers.find(({ config }) => {
          return config.layerType === PERSONA_STATIONS
        })

        if (!stationsLayer) {
          return []
        }

        return this.renderingAdapter.adapter.convertStations(
          stationsLayer.data,
          this.projection.value,
          this.$el,
        )
      },
      handleScale() {
        this.projection.value.fitExtent(
          getMapExtent(this.mapPadding, this.width, this.height),
          MAP_DATA_SPHERE,
        )

        this.baseScale = this.projection.value.scale()
        setScaleProperty(this.$el, this.zoom * this.baseScale)
      },
      draw() {
        if (!this.projection.value) {
          return
        }

        this.projection.value.fitExtent(
          getMapExtent(this.mapPadding, this.width, this.height),
          MAP_DATA_SPHERE,
        )

        this.projection.value.precision(0.5)
        this.projection.value.rotate(this.rotation)
        this.projection.value.scale(this.zoom * this.baseScale)

        this.renderingAdapter.adapter.draw({
          element: this.$el,
          layers: this.layers,
          projection: this.projection.value,
          theme: this.theme,
          width: this.width,
          height: this.height,
          hasWatermark: this.hasWatermark,
        })

        this.$emit('map-drawn', {
          adapter: this.renderingAdapter.adapter,
          element: this.$el,
          stations: this.getStations(),
        })
      },
      implementRotation() {
        geoInertiaDrag(select(this.$el), this.throttledOnDragging, this.projection.value, {
          start: this.onDragStart,
          end: this.onDragEnd,
          time: DRAG_TIMEOUT,
        })
      },
      implementZoom() {
        const zoomHandler = implementZoom({
          baseScale: this.baseScale,
          baseScaleExtent: this.allowZoom ? MAP_SCALE_EXTENT : [1, 1],
          zoomFactor: this.zoom,
        })
        select(this.$el).call(zoomHandler.on('zoom', this.throttledOnZooming))
      },
      onDragStart() {
        this.$emit('map-interaction', { isDragging: true })

        clearTimeout(DRAG_END_TIMEOUT)

        DRAG_END_TIMEOUT = setTimeout(() => {
          this.$emit('map-interaction', { isDragging: false })
        }, DRAG_TIMEOUT)
      },
      onDragging() {
        const rotation = this.projection.value.rotate()
        this.updateMapConfig({ id: this.id, rotation })
      },
      onDragEnd() {
        this.$emit('map-interaction', { isDragging: false })
      },
      onZooming(event) {
        const zoom = event.transform.k / this.baseScale
        this.updateMapConfig({ id: this.id, zoom })
      },
    },
  }
</script>
